<!-- 会员卡券信息 -->
<template>
    <div class="coupon-info">
        <list-search-bar @search.stop="handleSearch" inline
            @clear.stop="handleClearBtn" :offset="60">
                <el-form-item label="状态">
                    <el-select v-model="SP_.searchState" clearable>
                        <el-option
                            v-for="opt in stateList"
                            :key="opt.value"
                            :label="opt.name"
                            :value="opt.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
        </list-search-bar>


        <el-table :data="tableData" class="coupon-list">
            <el-table-column
                label="编号"
                prop="id"
                width="60"
            ></el-table-column>
            <el-table-column
                label="卡劵名称"
                prop="coupon_name"
            ></el-table-column>
            <el-table-column
                label="抵扣价值"
                prop="coupon_amount"
            ></el-table-column>
            <el-table-column
                label="状态"
                prop="statusName">
                <template slot-scope="scope">
                    <span class="state-signal" :style="{background: stateList[scope.row.status].color}"></span>{{ scope.row.statusName }}
                </template>
            </el-table-column>
            <el-table-column
                label="领取时间"
                prop="longcreatetime">
                <template slot-scope="scope">
                    <div v-html="scope.row.longcreatetime"></div>
                </template>
            </el-table-column>
            <el-table-column
                label="到期时间"
                prop="longcouponendtime">
                <template slot-scope="scope">
                    <div v-html="scope.row.longcouponendtime"></div>
                </template>
            </el-table-column>
            <el-table-column
                label="使用时间/失效时间"
                prop="longmaketime">
                <template slot-scope="scope">
                    <div v-html="scope.row.longmaketime"></div>
                </template>
            </el-table-column>
            <el-table-column
                label="用户是否领取"
                prop="drawStateName"
            ></el-table-column>
            <el-table-column
                label="备注"
                prop="remark"
            ></el-table-column>
        </el-table>

        <list-foot-bar>
            <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :current-page.sync="SP.pageNum"
                :page-size="SP.pageSize"
                :total="total"
                @size-change="handlePageSizeChange"
                @current-change="handlePageNoChange"
            ></el-pagination>
        </list-foot-bar>
    </div>
</template>

<script>
    import forList from "@/mixin/forList";
    const stateList = [
        {
            value: 0,
            name: "失效",
            color: "#C5C6CA"
        },
        {
            value: 1,
            name: "可使用",
            color: "#2DA641"
        },
        {
            value: 2,
            name: "已使用",
            color: "#F6BB90"
        },
        {
            value: 3,
            name: "已作废",
            color: "#C5C6CA"
        },
        {
            value: 4,
            name: "已转增",
            color: "#F6BB90"
        },
        {
            value: 5,
            name: "待解锁",
            color: "#C5C6CA"
        }
    ];
    export default {
        name        : 'CustomerCoupon',
        mixins      : [forList],
        noFetchFirst: true,
        noSaveState : true,
        props       : ["query"],

        data () {
            return {
                stateList,
                SP_: {
                    pageSize: 10,
                    pageNum: 1,
                    searchState: ""
                },
                total: 0,
                tableData: [],
            }
        },

        mounted () {
            if (this.query.id || this.query.custCode) {
                this.fetchData();
            }
        },

        methods: {
            async fetchData () {
                const loading = this.$loading({
                    target: ".coupon-info"
                });

                try {
                    const res = await this.$axios({
                        method: "post",
                        url   : "out/queryCustCouponInfo",
                        data  : {
                            ...this.query,
                            ...this.extractParams()
                        }
                    });
    
                    if (res.code !== 2000 && res.code !== 1003) throw res;
    
                    this.total     = res.data.count;
                    this.tableData = res.data.custCouponList.map(item => {
                        item.longcreatetime    = item.longcreatetime?.replace(" ", "<br/>") || "";
                        item.longcouponendtime = item.longcouponendtime?.replace(" ", "<br/>") || "";
                        item.longmaketime      = item.longmaketime?.replace(" ", "<br/>") || "";
                        item.coupon_amount     = item.coupon_amount ? (item.coupon_amount + "元") : "";
                        return item;
                    });
                    
                } catch (error) {
                    console.warn("获取会员卡券信息失败", error);
                    this.$message({
                        message: error.msg || "获取会员卡券信息失败",
                        type: "warning"
                    });
                }

                loading?.close();
            },
        }
    }
</script>

<style lang='scss' scoped></style>