// 用户管理

export default {
    path: "/user",
    name: "User",
    component: () =>
        import(
            /* webpackChunkName: "user" */
            "../views/user/user.vue"
        ),
    title: "用户管理",
    icon: "user",

    children: [
        {
            path: "user",
            name: "UserList",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/userList/userList.vue"
                ),
            title: "用户列表",
        },
        {
            path: "pure",
            name: "PureList",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/pureList/pureList.vue"
                ),
            title: "用户列表",
        },
        {
            path: "user/:id/:tab",
            name: "UserDetail",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/userDetail/userDetail.vue"
                ),
            title: "会员详情",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "coupon",
            name: "Coupon",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/couponList/couponList.vue"
                ),
            title: "房屋优惠券",
        },
        {
            path: "voucher",
            name: "voucher",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/voucherList/voucherList.vue"
                ),
            title: "已领取优惠券",
        },
        {
            path: "giftActivityList",
            name: "GiftActivityList",
            component: () =>
                import(
                    "../views/user/giftActivityList/giftActivityList.vue"
                ),
            title: "赠礼活动",
        },

        {
            path: "giftActivityList/:id",
            name: "GiftActivityDetail",
            component: () =>
                import(
                    "../views/user/giftActivityDetail/giftActivityDetail.vue"
                ),
            title: "赠礼活动客户明细",
            awayMenu: true // 不出现在导航菜单上
        },
        // 
        {
            path: "redemption",
            name: "Redemption",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/redemptionList/redemptionList.vue"
                ),
            title: "会员兑现列表",
        },
        {
            path: "signInLogs",
            name: "SignInLogs",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/signInLogs/signInLogs.vue"
                ),
            title: "登录监控",
        },
        {
            path: "signUpLogs",
            name: "SignUpLogs",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/signInLogs/signInLogs.vue"
                ),
            title: "用户注册监控",
        },
        {
            path: "scoreLogs",
            name: "ScoreLogs",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/scoreLogs/scoreLogs.vue"
                ),
            title: "积分日志",
        },
        {
            path: "financeScoreLogs",
            name: "FinanceScoreLogs",
            component: () =>
                import(
                    "../views/user/financeScoreLogs/financeScoreLogs.vue"
                ),
            title: "财务积分日志",
        },
        {
            path: "getticketlogs",
            name: "getTicketLogs",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/getTicketLogs/getTicketLogs.vue"
                ),
            title: "扫码领劵日志",
        },
        {
            path: "follower",
            name: "Follower",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/follower/follower.vue"
                ),
            title: "公众号粉丝",
        },
        {
            path: "irregular",
            name: "Irregular",
            component: () =>
                import(
                    /* webpackChunkName: "user" */
                    "../views/user/irregular/irregular.vue"
                ),
            title: "无区域客户",
        }
    ],
};