import ViewSafe from "./ViewSafe.vue";
import MD5 from "blueimp-md5";

export default {
    name: "Login",
    components: {
        "view-safe": ViewSafe
    },

    data() {
        return {
            sysName: '',
            mainPath: '',
            loginPath: '',


            hasLoading: false,
            hasLoadings: [],
            hasCreateLoadings: false,
            loginForm: {
                phone: "",
                password: "",
                hasValidate: false,
            },
            loginFormRules: {
                phone: [
                    {
                        required: true,
                        message: "请输入管家后台员工账号",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
                hasValidate: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (!this.validateStatus) {
                                callback(new Error("请点击验证"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "change",
                    },
                ],
            },
            validateStatus: false,
            hasResetValidateStyle: false,
        };
    },

    watch: {
        validateStatus(nval) {
            if (nval) this.$refs.loginForm.clearValidate("hasValidate");
        },
    },
    created() {
        this.initPage()
    },

    methods: {
        async initPage() {
            const loading = this.$loading();
            const res = await this.$axios({
                url: "/api/initPage",
                method: "get",

            });
            if (res.code === 2000) {
                loading.close();
                console.log('接口请求完成');
                localStorage.setItem("sysIconMap", JSON.stringify(res.data.sysIconMap));
                if (res.data.sysIconMap) {
                    if (res.data.sysIconMap.mainPath) {
                        this.mainPath = res.data.sysIconMap.realMainPath
                    } else {
                        this.mainPath = require('./images/logo.png')
                    }

                    if (res.data.sysIconMap.loginPath) {
                        this.loginPath = res.data.sysIconMap.realLoginPath
                    } else {
                        this.loginPath = require('./images/layer.png')
                    }

                    if (res.data.sysIconMap.name) {
                        this.sysName = res.data.sysIconMap.name
                    } else {
                        this.sysName = '管家中心'
                    }

                    if (res.data.sysIconMap.sysPath) {
                        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                        link.type = 'image/x-icon';
                        link.rel = 'shortcut icon';
                        link.href = res.data.sysIconMap.realSysPath;
                        document.getElementsByTagName('head')[0].appendChild(link);
                    } else {
                        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                        link.type = 'image/x-icon';
                        link.rel = 'shortcut icon';
                        // link.href = require('./images/favicon.ico');
                        link.href = require('./images/logo.png');
                        document.getElementsByTagName('head')[0].appendChild(link);

                    }
                }

            } else {
                loading.close();
                this.$message({
                    message: res.msg || "请求数据失败",
                    type: "warning",
                });
            }
        },
        createLoadingData(count) {
            const hasLoadings = [];
            for (let i = 0; i < count; i++) {
                hasLoadings.push(false);
            }
            this.hasCreateLoadings = true;
            this.hasLoadings = hasLoadings;
        },
        loading(currIdx) {
            if ((this.hasCreateLoadings && currIdx !== null) || currIdx !== undefined) {
                this.hasLoadings[currIdx] = true;
                return;
            }
            this.hasLoading = true;
        },
        loaded(currIdx) {
            if (
                (this.hasCreateLoadings && currIdx !== null) ||
                currIdx !== undefined
            ) {
                this.hasLoadings[currIdx] = false;
                return;
            }
            this.hasLoading = false;
        },
        handleSubmitLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.requestLogin();
                } else {
                    return false;
                }
            });
        },
        requestLogin() {
            this.loading();
            this.$oxios({
                url: "api/login",
                method: "post",
                data: {
                    account: this.loginForm.phone,
                    password: MD5(this.loginForm.password),
                }
            })

            .then((res) => {
                if (res.code === 2000) {
                    this.$store.commit("setToken", res.data.token);
                    const {userSession} = res.data;
                    const {pathList} = userSession;
                    // #region 这一部分代码转化后端数据结构为本地数据结构
                    // 因为前期写目录功能的时候还没有动态目录的数据接口，所
                    // 以这里后来接到的数据结构和本地数据结构不一样。
                    pathList.forEach(item => {
                        item.icon = item.webpath?.split("/")?.[1];
                        item.name = item.title = item.menuname;
                        item.api = item.path;
                        item.path = item.webpath;
                    });
                    // #endregion 
                    this.$store.commit("setUserInfo", userSession);
                    this.$router.replace({ path: "/home" });
                } else {
                    this.$message({
                        message: res.msg || "登录失败，请稍后重试",
                        type: "error"
                    });
                    this.resetValidateStyle();
                }
                this.loaded();
            })

            .catch(reason => {
                this.$message({
                    message: "登录失败，请稍后重试",
                    type: "error"
                });
                console.warn("登录程序出错", reason)
                this.resetValidateStyle();
                this.loaded();
            });
        },
        resetValidateStyle() {
            this.validateStatus = false;
            this.hasResetValidateStyle = true;
        },
    },
};