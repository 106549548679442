// 订单管理

export default {
    path: "/order",
    name: "Order",
    component: () =>
        import(
            /* webpackChunkName: "order" */
            "../views/order/order.vue"
        ),
    title: "订单管理",
    icon: "order",

    children: [
        {
            path: "list",
            name: "OrderList",
            component: () =>
                import(
                    /* webpackChunkName: "order" */
                    "../views/order/orderList/orderList.vue"
                ),
            title: "订单列表",
        },
        {
            path: "list/:id/:tab",
            name: "ListDetail",
            component: () =>
                import(
                    /* webpackChunkName: "order" */
                    "../views/order/orderDetail/orderDetail.vue"
                ),
            title: "订单详情", 
            awayMenu: true
        },


        {
            path: "aftersales",
            name: "AfterSales",
            component: () =>
                import(
                    /* webpackChunkName: "order" */
                    "../views/order/afterSales/afterSales.vue"
                ),
            title: "订单售后申请",
        },
        {
            path: "aftersales/:id",
            name: "AfterDetail",
            component: () =>
                import(
                    /* webpackChunkName: "order" */
                    "../views/order/afterDetail/afterDetail.vue"
                ),
            title: "立即处理", 
            awayMenu: true
        },
    ],
}