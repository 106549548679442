// 基础设置

export default {
    path: "/basic",
    name: "Basic",
    component: () =>
        import(
            /* webpackChunkName: "basic" */
            "../views/basic/basic.vue"
        ),
    title: "基础设置",
    icon: "basic",

    children: [
        {
            path: "post",
            name: "BasicPost",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/basicPost/basicPost.vue"
                ),
            title: "岗位管理",
        },


        {
            path: "maintainer",
            name: "BasicMaintainer",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/basicMaintainer/basicMaintainer.vue"
                ),
            title: "运维账号",
        },
        {
            path: "maintainer/add",
            name: "BasicMaintainerAdd",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/basicMaintainer/basicMaintainerEdit/basicMaintainerEdit.vue"
                ),
            title: "新增运维账号",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "maintainer/edit/:id",
            name: "BasicMaintainerEdit",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/basicMaintainer/basicMaintainerEdit/basicMaintainerEdit.vue"
                ),
            title: "编辑运维账号",
            awayMenu: true // 不出现在导航菜单上
        },


        {
            path: "banner",
            name: "Banner",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/banner/banner.vue"
                ),
            title: "banner设置",
        },
        {
            path: "banner/add",
            name: "BannerAdd",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/banner/bannerEdit/bannerEdit.vue"
                ),
            title: "新增轮播图",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "banner/edit/:id",
            name: "BannerEdit",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/banner/bannerEdit/bannerEdit.vue"
                ),
            title: "编辑轮播图",
            awayMenu: true // 不出现在导航菜单上
        },


        {
            path: "voucher",
            name: "Voucher",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/voucher/voucher.vue"
                ),
            title: "券类型",
        },
        {
            path: "voucher/add",
            name: "VoucherAdd",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/voucherEdit/voucherEdit.vue"
                ),
            title: "新增券",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "voucher/edit/:id",
            name: "VoucherEdit",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/voucherEdit/voucherEdit.vue"
                ),
            title: "编辑券",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "voucher/message/:id",
            name: "VoucherMessage",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/voucherMessage/voucherMessage.vue"
                ),
            title: "消息设置",
            awayMenu: true // 不出现在导航菜单上
        },


        {
            path: "news",
            name: "BasicNews",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/basicNews/basicNews.vue"
                ),
            title: "资讯配置",
        },
        {
            path: "news/edit/:id",
            name: "NewsEdit",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/basicNews/newsEdit.vue"
                ),
            title: "编辑资讯",
            awayMenu: true // 不出现在导航菜单上
        },


        {
            path: "faq",
            name: "BasicFAQ",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/basicFAQ/basicFAQ.vue"
                ),
            title: "常见问题",
        },
        {
            path: "faq/add",
            name: "FAQAdd",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/basicFAQ/faqEdit.vue"
                ),
            title: "新增常见问题",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "faq/edit/:id",
            name: "FAQEdit",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/basicFAQ/faqEdit.vue"
                ),
            title: "编辑常见问题",
            awayMenu: true // 不出现在导航菜单上
        },

        {
            path: "benefits",
            name: "Benefits",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/benefits/benefits.vue"
                ),
            title: "会员权益设置",
        },
        {
            path: "benefits/add",
            name: "benefitsAdd",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/benefitsAdd/benefitsAdd.vue"
                ),
            title: "新增会员权益",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "benefits/edit/:id",
            name: "benefitsEdit",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/benefitsAdd/benefitsAdd.vue"
                ),
            title: "编辑会员权益",
            awayMenu: true // 不出现在导航菜单上
        },

        {
            path: "level",
            name: "memberLevel",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/memberLevel/memberLevel.vue"
                ),
            title: "会员等级",
        },
        {
            path: "level/add",
            name: "memberLevelAdd",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/memberLevelEdit/memberLevelEdit.vue"
                ),
            title: "会员等级新增",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "level/edit/:id",
            name: "memberLevelEdit",
            component: () =>
                import(
                    /* webpackChunkName: "basic" */
                    "../views/basic/memberLevelEdit/memberLevelEdit.vue"
                ),
            title: "会员等级编辑",
            awayMenu: true // 不出现在导航菜单上
        },
    ],
};