
var numeral = require('numeral');

/**
 * extract 增强了 Array.prototype.filter，它额外的返
 * 回了后者未匹配的元素数组
 * @param {Funtion} fn filter判断函数
 * @returns {Object} {
 *    extractive: 符合条件的元素数组
 *    rest: 不符合条件的元素数组
 * }
 */
Array.prototype.extract = function (fn) {
    const extractive = this.filter(fn);
    const rest = this.filter(item => !fn(item));

    return {extractive, rest}
}

// 字符串的连字符转换成驼峰
String.prototype.toCamel = function () {
    return this.replace(/([A-Za-z0-9]*)[-,_]([A-Za-z0-9]*)/g, function(_, left, right) {
        return left + right[0].toUpperCase() + right.slice(1)
    })
}

// 字符串的连字符转换成驼峰
String.prototype.toHyphen = function (hy = "-") {
    return this.replace(/([a-z0-9]*)([A-Z])/g, function(_, left, right) {
        return left + hy + right.toLowerCase()
    })
}

// 给富文本数据（String）里面的图片链接加上域名
export function addHostTOImgStr(str, host) {
    const reg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g;
    const list = str.match(reg);
    list && list.forEach(imgstr => {
        const src = imgstr.replace(reg, "$1");
        if (!src.startsWith("http")) {
            str = str.replace(new RegExp(src, "g"), host + src)
        }
    })
    return str;
}
String.prototype.addHostToImg = function (host) {
    return addHostTOImgStr(this, host)
}

// 去掉富文本数据（String）里面的图片链接的域名
export function removeHostFromImgStr(str, host) {
    if (host) {
        if (host.endsWith("/")) host = host.slice(0, -1);
    } else host = `http(s)?://[^'"/]+`

    const reg = new RegExp(`<img [^>]*src=['"](${host})[/'"][^>]*>`, "g");
    const list = str.match(reg);
    list && list.forEach(imgstr => {
        const _host = imgstr.replace(reg, "$1");
        str = str.replace(imgstr, imgstr.replace(_host, ""))
    })
    return str;
}
String.prototype.removeImgHost = function (host) {
    return removeHostFromImgStr(this, host)
}


String.prototype.pushToClipBoard = function () {
    let transfer = document.createElement('input');
    document.body.appendChild(transfer);
    transfer.value = this;
    transfer.focus();
    transfer.select();
    if (document.execCommand('copy')) {
        document.execCommand('copy');
        document.body.removeChild(transfer);
        return true;
    } else {
        document.body.removeChild(transfer);
        return false;
    }
}
/**
 * 金额转换成又逗号分割格式
 * @Author zhuiyi
 * @paramsource数据源
 * @authorzhuiyi 2023/01/04
 */
export function NUMERAL(num) {
    if (num == '') {
        return ''
    } else {
        if ((num + '').indexOf(".") != -1) {
            return numeral(num).format('0,0.00')
        } else {
            return numeral(num).format('0,0')
        }
    }
}
/**
 * 深度克隆数据
 * @Author zhuiyi
 * @paramsource数据源
 * @authorzhuiyi 2023/07/10
 */
export function DEEPCLONE(source) {
    if (!source || typeof source !== 'object') {
        throw new Error('error arguments', 'shallowClone');
    }
    var targetObj = source.constructor === Array ? [] : {};
    for (var keys in source) {
        if (source.hasOwnProperty.call(source, keys)) {
            if (source[keys] && typeof source[keys] === 'object' && !(source[keys] instanceof Date)) {
                targetObj[keys] = source[keys].constructor === Array ? [] : {};
                targetObj[keys] = DEEPCLONE(source[keys]);
            } else {
                targetObj[keys] = source[keys];
            }
        }
    }
    return targetObj;
}