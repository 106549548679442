// 消息通知

export default {
    path: "/msgs",
    name: "Messages",
    component: () =>
        import(
            /* webpackChunkName: "messages" */
            "../views/messages/messages.vue"
        ),
    title: "消息通知",
    icon: "message",
    
    children: [
        {
            path: "files",
            name: "Files",
            component: () =>
            import(
                /* webpackChunkName: "messages" */
                "../views/messages/files/files.vue"
            ),
            title: "下载通知",
            // awayMenu: true, // 不在左侧导航
        },
        {
            path: "wxMessage",
            name: "WXMessage",
            component: () =>
            import(
                /* webpackChunkName: "messages" */
                "../views/messages/wxMessage/wxMessage.vue"
            ),
            title: "微信消息反馈",
            // awayMenu: true, // 不在左侧导航
        }
    ],
}