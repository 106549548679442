// 商品列表

export default {
    path: "/goods",
    name: "Goods",
    component: () =>
        import(
            /* webpackChunkName: "goods" */
            "../views/goods/goods.vue"
        ),
    title: "商品管理",
    icon: "goods",

    children: [
        //#region 商品分类
        {
            path: "category",
            name: "GoodsCategory",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/category/category.vue"
                ),
            title: "商品分类",
        },
        {
            path: "category/add",
            name: "GoodsCategoryAdd",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/categoryEdit/categoryEdit.vue"
                ),
            title: "新增分类",
            awayMenu: true
        },
        {
            path: "category/edit/:id",
            name: "GoodsCategoryEdit",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/categoryEdit/categoryEdit.vue"
                ),
            title: "编辑分类",
            awayMenu: true
        },
        //#endregion

        //#region 商品规格
        {
            path: "specification",
            name: "GoodsSpecification",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/specification/specification.vue"
                ),
            title: "商品规格",
        },
        {
            path: "specification/add",
            name: "GoodsSpecificationAdd",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/specificationEdit/specificationEdit.vue"
                ),
            title: "新增规格",
            awayMenu: true
        },
        {
            path: "specification/edit/:id",
            name: "GoodsSpecificationEdit",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/specificationEdit/specificationEdit.vue"
                ),
            title: "编辑规格",
            awayMenu: true
        },
        //#endregion
        // 服务商
        //#serviceMerchant 服务商
        {
            path: "serviceMerchant",
            name: "ServiceMerchant",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/serviceMerchant/serviceMerchant.vue"
                ),
            title: "服务商管理",
        },
        {
            path: "serviceMerchant/add",
            name: "ServiceMerchantAdd",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/serviceMerchantEdit/serviceMerchantEdit.vue"
                ),
            title: "新增服务商",
            awayMenu: true
        },
        {
            path: "serviceMerchant/edit/:id",
            name: "ServiceMerchantEdit",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/serviceMerchantEdit/serviceMerchantEdit.vue"
                ),
            title: "编辑服务商",
            awayMenu: true
        },
        //#end服务商

        //#region 预约模板
        {
            path: "tablemode",
            name: "GoodsTableMode",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/tableMode/tableMode.vue"
                ),
            title: "商品预约模板",
        },
        {
            path: "tablemode/add",
            name: "GoodsTableModeAdd",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/tableModeEdit/tableModeEdit.vue"
                ),
            title: "新增商品预约模板",
            awayMenu: true
        },
        {
            path: "tablemode/edit/:id",
            name: "GoodsTableModeEdit",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/tableModeEdit/tableModeEdit.vue"
                ),
            title: "编辑商品预约模板",
            awayMenu: true
        },
        //#endregion
        
        // {
        //     path: "businesses",
        //     name: "GoodsBusinesses",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "goods" */
        //             "../views/goods/goodsBasic/systemBasic.vue"
        //         ),
        //     title: "服务商配置",
        // },

        {
            path: "goods",
            name: "GoodsList",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/goodsList/goodsList.vue"
                ),
            title: "商品列表",
        },
        {
            path: "goods/edit/:id",
            name: "GoodsEdit",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/goodsAdd/goodsAdd.vue"
                ),
            title: "编辑商品",
            awayMenu: true
        },
        {
            path: "goods/add",
            name: "GoodsAdd",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/goodsAdd/goodsAdd.vue"
                ),
            title: "新增商品",
            awayMenu: true
        },

        // {
        //     path: "recycler",
        //     name: "GoodsRecycler",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "goods" */
        //             "../views/goods/goodsBasic/systemBasic.vue"
        //         ),
        //     title: "商品回收站",
        // },

        {
            path: "comment",
            name: "commentList",
            component: () =>
                import(
                    /* webpackChunkName: "goods" */
                    "../views/goods/commentList/commentList.vue"
                ),
            title: "商品评论列表",
        },
    ],
}