import system from "./module_1_system";          // 系统设置模块的路由
import basic from "./module_2_basic";            // 基础设置模块的路由
import user from "./module_3_user";              // 用户管理模块的路由
import goods from "./module_4_goods";            // 用户管理模块的路由
import order from "./module_5_order";            // 订单管理模块的路由
import my from "./module_6_my";                  // 我的账户模块的路由
import statistics from "./module_9_statistics";  // 数据统计模块的路由
import messages from "./module_99_messages";     // 消息通知模块的路由


import layout from "../views/main/layout/layout.vue"; // 布局框架
import login from "../views/main/login/login.vue";    // 登录组件
import customerOpen from "../views/main/customerOpen/customerOpen.vue";    // 不需要登录的开放的用户信息查询页面
// import login1 from "../views/main/login1/login.vue";    // 登录组件
import home from "../views/main/home/home.vue";       // 首页组件

const options = [
	{
		path: "",
		name: "Main",
		title: "首页",
		component: layout,
		redirect: "/home",
		children: [
			{
				path: "/home",
				name: "Home",
				component: home,
				title: "主页",
				descreption: "返回首页",
				icon: "home",
			}
		
			, system     // 系统设置
			, basic      // 基础设置
			, user       // 用户管理
			, goods      // 商品管理
			, order      // 订单管理
			, my         // 账号中心
			, statistics // 数据统计
			, messages   // 消息通知

		]
	},
	{
		path: "/login",
		name: "Login",
		component: login,
		title: "登录",
		icon: "",
		awayMenu: true
	},
	{
		path: "/customerOpen",
		name: "CustomerOpen",
		component: customerOpen,
        title: "金顶管家的会员信息",
		icon: "",
		awayMenu: true
	}
];



/**
 * routeList，用于在 router 中生成 routes
 */
function extractRouteOption(item) {
	// const { path, name, component, title } = item;
	if (item.children && item.children.length) {
		item.children = item.children.map(extractRouteOption);
	}
	item.meta = {title: item.title}
	return item;
}

const routeList = options.map(extractRouteOption);



/**
 * menuList，用于再 App 中导入，并渲染左侧导航目录
 */
function extractMenuProps(_item) {
	const { path, title, icon } = _item;
	const item = { path, title, icon };

	if (_item.children && _item.children.length && !_item.awayMenu) {
		item.children = _item.children.filter(it => !it.awayMenu).map(extractMenuProps);
	}

	return item
}

const menuList = options[0].children.filter(item => !item.awayMenu).map(extractMenuProps);



export { routeList, menuList };
export default options;