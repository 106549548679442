<!-- 会员信息 -->
<template>
    <div class="customer-info">
        <ul class="user-brief">
            <li>
                <span class="tag">客户编号</span>
                <span class="value">{{ custInfo.cust_code }}</span>
            </li>
            <li>
                <span class="tag">客户名称</span>
                <span class="value">{{ custInfo.name }}</span>
            </li>
            <li>
                <span class="tag">电话号码</span>
                <span class="value">{{ custInfo.phone }}</span>
            </li>
            <li>
                <span class="tag">积分卡等级</span>
                <span class="value">{{ custInfo.vipname }}</span>
            </li>
            <li>
                <span class="tag">可用积分</span>
                <span class="value">{{ custInfo.integral }}(已冻结积分: {{ custInfo.locked_integral }})</span>
            </li>
            <li>
                <span class="tag">是否登录过</span>
                <span class="value">{{ custInfo.first_loginName }}</span>
            </li>
            <li>
                <span class="tag">是否关注尚澳服务号</span>
                <span class="value">{{ custInfo.is_fansName }}</span>
            </li>
        </ul>

        <el-table :data="tableData" class="bill-list">
            <el-table-column
                label="类型"
                prop="typeName"
                width="150"
            ></el-table-column>
            <el-table-column
                label="积分"
                prop="_money"
                width="100"
            ></el-table-column>
            <el-table-column
                label="时间"
                prop="create_time"
            ></el-table-column>
            <el-table-column
                label="备注"
                prop="change_desc"
                min-width="200%"
            ></el-table-column>
        </el-table>

        <list-foot-bar>
            <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :current-page.sync="SP.pageNum"
                :page-size="SP.pageSize"
                :total="total"
                @size-change="handlePageSizeChange"
                @current-change="handlePageNoChange"
            ></el-pagination>
        </list-foot-bar>
    </div>
</template>

<script>
    export default {
        name: 'CustomerInfo',
        props: ["query"],

        data () {
            return {
                custInfo: {},
                SP: {
                    pageSize: 10,
                    pageNum: 1,
                },
                total: 0,
                tableData: [],
            }
        },

        mounted () {
            if (this.query.id || this.query.custCode) {
                this.fetchData();
            }
        },

        methods: {
            async fetchData () {
                await Promise.allSettled([this.fetchInfo(), this.fetchBillList()]);
                console.log("请求用户数据和用户积分明细完毕");
            },

            async fetchInfo () {
                const loading = this.$loading({
                    target: ".user-brief"
                });

                try {
                    const res = await this.$axios({
                        method: "post",
                        url   : "out/queryCustInfo",
                        data  : {
                            ...this.query
                        }
                    });
    
                    if (res.code !== 2000 && res.code !== 1003) throw res;
    
                    this.custInfo = res.data.custInfo;
                    
                } catch (error) {
                    console.warn("获取积分明细失败", error);
                    this.$message({
                        message: error.msg || "获取积分明细失败",
                        type: "warning"
                    });
                }

                loading?.close();
            },

            async fetchBillList () {
                const loading = this.$loading({
                    target: ".bill-list"
                });

                try {
                    const res = await this.$axios({
                        method: "post",
                        url   : "out/queryCustBillDetailInfo",
                        data  : {
                            ...this.query,
                            ...this.SP
                        }
                    })
    
                    if (res.code !== 2000 && res.code !== 1003) throw res;
    
                    this.total     = res.data.count;
                    this.tableData = res.data.billDetailList.map(item => {
                        const dist = {
                            1: "+",
                            2: "-",
                            get undefined () {
                                return this[1]
                            },
                            get null () {
                                return this[1]
                            }
                        }
                        item._money = dist[item.up_down] + item.money; 
                        return item;
                    })
                    
                } catch (error) {
                    console.warn("获取积分明细失败", error);
                    this.$message({
                        message: error.msg || "获取积分明细失败",
                        type: "warning"
                    });
                }

                loading?.close();
            },

            handlePageSizeChange(e) {
                this.SP.pageSize = e;
                this.SP.pageNum = 1;
                this.fetchBillList();
            },

            handlePageNoChange(e) {
                this.SP.pageNum = e;
                this.fetchBillList();
            },

        }
    }
</script>

<style lang='scss' scoped>
    .user-brief {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -16px;
        padding: 9px 0;

        li {
            flex: 0 0 33%;
            margin-bottom: 16px;

            .tag {
                white-space: nowrap;

                &:after {
                    content: ":";
                    margin-right: 6px;
                }
            }

            .value {
                color: rgba($color: #000000, $alpha: .65);
            }
        }
    }
</style>