export default function clearListPageStateOnCreated () {
    /**
     * 约定: noSaveState 为
     * true: 不对listPageState做任何操作
     * 1:    不保存，但要清除
     * 
     * noFetchFirst 为真时，列表页进入后不自动请求数据
     * 
     * ！使用时注意绑定this
     */
    const {name, noSaveState, noFetchFirst} = this.$options;
    if (!noSaveState) {
        const {listPageState} = this.$store.state;
        if (listPageState) {
            if (listPageState?.name === name) {
                const {params} = listPageState;
                if (params instanceof Object) {
                    for (let key in params) this.SP[key] = params[key];
                    if (this.updateSPtoSP_) this.updateSPtoSP_();
                    
                    // 如果页面定义了回调（需要拿到状态执行更多操作），执行回调
                    const CB = this.callBackForListState;
                    if (typeof CB === "function") {
                        CB.call(this, params)
                    }
                }
            } else {
                // 清除缓存的列表状态
                this.$store.commit("saveListPageState");
            }
        }
    } else if (noSaveState === 1) {
        // 清除缓存的列表状态
        this.$store.commit("saveListPageState");
    }

    if (!noFetchFirst) this.fetchData();
}