import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import { routeList } from "./menu";
const routes = routeList;

// const routes: Array<RouteConfig> = [
// {
//   path: "/",
//   name: "Home",
//   component: Home,
// },
// {
//   path: "/about",
//   name: "About",
//   // route level code-splitting
//   // this generates a separate chunk (about.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () =>
//     import(/* webpackChunkName: "about" */ "../views/About.vue"),
// },
// ];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  base: "/JDGJ",
  routes
});

router.beforeEach((to, from, next) => {
	let token = localStorage.getItem('token');
	// console.log(to, "===")
	if (token || to.name == "CustomerOpen") { // 判断是否登录
		next()

	} else { // 未登录跳转登录
		if (to.path == '/login') {
			next()
		} else {
			next({ path: '/login' })
		}
      
	}
});

router.afterEach((to) => {
  // 设置页面标题
  document.title = to.meta.title;
})

export default router;
