<!-- 会员订单信息 -->
<template>
    <div class="order-info">
        <el-table :data="tableData" class="order-list">
            <!-- 订单折叠详情 -->
            <el-table-column type="expand">
                <template slot-scope="props">
                    <ul v-for="goods in props.row.orderGoodsList"
                        :key="goods.id"
                        class="goods-detail">
                        <li class="godds-name">
                            <span class="tag">项目名称</span>
                            <span class="value">{{ goods.goods_name }}</span>
                        </li>
                        <li class="godds-size">
                            <span class="value">{{ goods.goods_sku }}</span>
                        </li>
                        <li class="godds-count">
                            <span class="tag">数量</span>
                            <span class="value">{{ goods.num }}</span>
                        </li>
                        <li class="godds-amount">
                            <span class="tag">总价</span>
                            <span class="value">{{ goods.sale_price }}{{ props.row.pay_way == 1 ? "积分" : "元" }}</span>
                        </li>
                    </ul>
                </template>
            </el-table-column>
            <el-table-column
                label="订单编号"
                prop="order_no"
            ></el-table-column>
            <el-table-column
                label="下单日期"
                prop="createtime"
            ></el-table-column>
            <el-table-column
                label="订单总和(积分/元)"
                prop="order_price"
            ></el-table-column>
            <el-table-column
                label="支付方式"
                prop="pay_wayname"
            ></el-table-column>
            <el-table-column
                label="订单状态"
                prop="statename">
                <template slot-scope="scope">
                    <span class="state-signal" :class="{red: scope.row.state == 7}"></span>{{ scope.row.statename }}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        @click.stop="handleExpressBtn(scope.row)"
                    >物流信息</el-button>
                </template>
            </el-table-column>
        </el-table>

        <list-foot-bar>
            <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :current-page.sync="SP.pageNum"
                :page-size="SP.pageSize"
                :total="total"
                @size-change="handlePageSizeChange"
                @current-change="handlePageNoChange"
            ></el-pagination>
        </list-foot-bar>

        <ExpressList :item.sync="expressItem"></ExpressList>
    </div>
</template>

<script>
    import forList from "@/mixin/forList";
    import ExpressList from "./ExpressList.vue";
    export default {
        name        : 'CustomerOrder',
        mixins      : [forList],
        noFetchFirst: true,
        noSaveState : true,
        props       : ["query"],
        components  : {
            ExpressList
        },

        data () {
            return {
                SP_: {
                    pageSize: 10,
                    pageNum: 1
                },
                total: 0,
                tableData: [],

                expressItem: null
            }
        },

        mounted () {
            if (this.query.id || this.query.custCode) {
                this.fetchData();
            }
        },

        methods: {
            async fetchData () {
                const loading = this.$loading({
                    target: ".order-info"
                });

                try {
                    const res = await this.$axios({
                        method: "post",
                        url   : "out/queryCustOrderInfo",
                        data  : {
                            ...this.query,
                            ...this.extractParams()
                        }
                    });
    
                    if (res.code !== 2000 && res.code !== 1003) throw res;
    
                    this.total     = res.data.count;
                    this.tableData = res.data.orderList;

                } catch (error) {
                    console.warn("获取会员订单信息失败", error);
                    this.$message({
                        message: error.msg || "获取会员订单信息失败",
                        type: "warning"
                    });
                }

                loading?.close();
            },

            handleExpressBtn (item) {
                this.expressItem = item;
            },
        }
    }
</script>

<style lang='scss' scoped>
    .goods-detail {
        display: flex;
        line-height: 22px;

        &:nth-child(n+2) {
            margin-top: 16px;
        }

        li {
            flex: 0 0 25%;

            .tag {
                color: rgba($color: #000000, $alpha: .85);

                &:after {
                    content: ":";
                    margin-right: 6px;
                }
            }

            .value {
                color: rgba($color: #000000, $alpha: .65)
            }
        }
    }

    /deep/ {
        .el-table__expanded-cell {
            background-color: #F4F7FA;

            &:hover {
                background-color: #F4F7FA !important;
            }
        }
    }
</style>