// 数据统计

export default {
    path: "/statistics",
    name: "Statistics",
    component: () =>
        import(
            /* webpackChunkName: "statistics" */
            "../views/statistics/statistics.vue"
        ),
    title: "数据统计",
    icon: "statistics",

    children: [
        {
            path: "regular",
            name: "regularStatistics",
            component: () =>
                import(
                    /* webpackChunkName: "statistics" */
                    "../views/statistics/regular/regular.vue"
                ),
            title: "数据统计",
        },
        {
            path: "giftCoupon",
            name: "GiftCoupon",
            component: () =>
                import(
                    /* webpackChunkName: "statistics" */
                    "../views/statistics/giftCoupon/giftCoupon.vue"
                ),
            title: "赠品券统计",
        },
        {
            path: "vipGreat",
            name: "VipGreat",
            component: () =>
                import(
                    /* webpackChunkName: "statistics" */
                    "../views/statistics/vipGreat/vipGreat.vue"
                ),
            title: "会员等级统计",
        },
    ]
};