import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router"
Vue.use(Vuex)

const store = new Vuex.Store({

	state: {
		token: "",
		userInfo: {},             // 登录用户信息
		businessCategories: [],   // 业务方向的配置信息
		regionCategories: [],     // 区域配置
		postCategories: [],       // 岗位信息
		listPageState: null,      // 列表页记录状态, 便于从详情页返回时恢复
	},

	mutations: {

		// 设置token
		setToken: function (state, val) {
			state.token = val;
			localStorage.setItem("token", val);
		},

		// 更新登录用户信息
		setUserInfo(state, info) {
			if (info.constructor !== Object) {
				return console.warn("store 设置用户信息失败，传入的参数不是对象", info)
			}

			state.userInfo = Object.assign(state.userInfo, info);
			localStorage.setItem("userInfo", JSON.stringify(info));
		},

		// 清空用户信息，在推出登录时使用
		clearUserInfo(state) {
			state.userInfo = {};
			state.token = "";
			localStorage.removeItem("token");
			localStorage.removeItem("userInfo");
			router.replace("/login");
		},

		// 更新业务方向的配置信息
		setBusinessCategories(state, list) {
			if (!(list instanceof Array)) {
				return console.warn("store 设置业务方向的配置信息失败，传入的参数不是数组", list);
			}
			state.businessCategories = list;
		},

		// 更新区域的配置信息
		setRegionCategories(state, list) {
			if (!(list instanceof Array)) {
				return console.warn("store 设置区域的配置信息失败，传入的参数不是数组", list);
			}
			state.regionCategories = list;
		},

		// 更新岗位的配置信息
		setPostCategories(state, list) {
			if (!(list instanceof Array)) {
				return console.warn("store 设置岗位的配置信息失败，传入的参数不是数组", list);
			}
			state.postCategories = list;
		},

		// 记录列表页的状态
		saveListPageState(state, data) {
			if (data == undefined) {
				state.listPageState = null;
                console.log("已清空列表页状态")
			} else {
				state.listPageState = data;
                console.log("保存列表页状态", data);
			}
		}
	}
})

const token = localStorage.getItem("token");
token && store.commit("setToken", token);
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
userInfo && store.commit("setUserInfo", userInfo)

const hasAuthFor = function (apiPath) {
	const {pathList} = store.state.userInfo;
	if (pathList.constructor !== Array) return false;
	return pathList.some(item => item.api.endsWith(apiPath))
};

Vue.prototype.$hasAuthFor = hasAuthFor;
export default store;