// 我的账号

export default {
    path: "/my",
    name: "My",
    component: () =>
        import(
            /* webpackChunkName: "my" */
            "../views/my/my.vue"
        ),
    title: "我的账号",
    icon: "my",
    awayMenu: true, // 不在左侧导航

    children: [
        {
            path: "updatePwd",
            name: "UpdatePwd",
            component: () =>
                import(
                    /* webpackChunkName: "my" */
                    "../views/my/updatePwd/updatePwd.vue"
                ),
            title: "修改密码",
        }
    ],
}