import CustomerInfo   from "./CustomerInfo.vue"
import CustomerCoupon from "./CustomerCoupon.vue"
import CustomerOrder from "./CustomerOrder.vue"

export default {
    name: "customerOpen",
    components: {
        CustomerInfo,
        CustomerCoupon,
        CustomerOrder
    },

    data () {
        return {
            query: {}
        };
    },

    created () {
        this.query = this.$route.query;
        // let _id = 0;

        // if (queryKeys.length == 1) {
        //     _id = parseInt(this.$route.query[queryKeys[0]]);
        // }
        // else if (queryKeys.length > 1) {
        //     let {id, userId, custId} = this.$route.query;
        //     _id = parseInt(id || userId || custId);
        // }

        
        // if (_id) this.id = _id;
        // else console.warn("url 没有携带查询参数")
    },

    watch: {},

    methods: {},
};