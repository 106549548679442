
import forList from "@/mixin/forList";
import forEdit from "@/mixin/forEdit";

// 函数创建并返回一个新的空的日程数据的方法
const initialPB = () => ({
	title: "",
    startTime: "",
    endTime: "",
    remark: ""
});

export default {
    name: 'Home',
	mixins: [forList, forEdit],
    noSaveState: 1,

    data() {
        return {
            SP: {
                sreachStartTime: "",
                sreachEndTime: ""
            },
            tableData: {
                /**
                 * tableData 用日期作为属性名，一个数组作为属性值，数组元素为当天
                 * 的日程数据
                 * "2021-08-23", [
                        {
                            title: "日程一",
                            startTime: "2021-09-03",
                            endTime: "2021-09-03",
                            remark: "详情详情详情详情详情详情详情详情",
                        }
                    ]
                 */
            },

            PB: null,          // 新日程的编辑数据
            rules: {
                title: {
                    required: true,
					trigger: "blur",
                    message: "请输入主题"
                },
                startTime: {
                    required: true,
					trigger: "blur",
                    message: "请选择开始时间"
                },
                endTime: {
                    required: true,
					trigger: "blur",
                    message: "请选择结束时间"
                }
            },
            detailItem: null,  // 日程详情数据，点击日程后，将被点击的数据赋值给它，用户渲染视图
            dragFromKey: "",   // 拖拽的标签对应的数据在 tableData 中的key (日期)
            dragFromIndex: "", // 拖拽的标签在对应的数据（数组）中的索引

            // endTimeOptions: {  // 结束时间的配置：不能小于开始时间
            //     disabledDate (time) {
            //         return time > this.planData.beginTime;
            //     }
            // }
        }
    },

    computed: {
        showDetailPopup () {
            return !!this.detailItem;
        },

		// 对话框状态由 PB 是否有值产生
		showAddPupop () {
			return !!this.PB
		},
    },

    methods: {

		// 获取日程数据
		fetchData() {
			if (this.loading) return;
			this.loading = true;

            const {sreachStartTime, sreachEndTime} = this.SP;
			this.$axios({
				url: "api/schedule/querySchedule",
				method: "post",
				data: {sreachStartTime, sreachEndTime}
			})
            .then(res => {
                if (res.code === 2000 && res.data.schedulelist) {
                    res.data.schedulelist.forEach(plan => {
                        plan.startTime = plan.startTime || plan.starttime || plan.start_time;
                        plan.endTime = plan.endTime || plan.endtime || plan.end_time;
                        
                        // const {tableData} = this;
                        const key = plan.startTime.slice(0, 10); // 获取日期，作为key
                        this.pushPlanToDate(plan, key);
                        // 如果当天没有日程数组，创建一个新的
                        // if (!(tableData[key] instanceof Array)) {
                        //     this.$set(tableData, key, []);
                        // }
                        // 添加这个日程到当天的数组里面
                        // tableData[key].push(plan);
                    });

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},

		initialPB, // 创建并返回一个新的空的日程数据的方法

		// 点击 "新增日程"
		handleAddBtn() {
			this.PB = this.initialPB();
		},

		// 保存对话框
		handSubmitButton() {
            let newPlan = null; // 表单验证后，将用来存储输入的数据
			this.$refs.form.validate()

            .then(result => {
                if (!result) {
                    return Promise.reject("请填写完整数据");
                }
                newPlan = {
                    ...this.PB
                };
                return this.saveSchedule(newPlan)
            })

            .then(res => {
                if (res.code === 2000) {
                    newPlan.id = res.data.scheduleid;
                    this.pushPlanToDate(newPlan, newPlan.startTime.slice(0,10));
                    this.PB = null;

                    this.$message({
                        message: "保存成功",
                        type: "success"
                    })
                } else if (res.code !== 1003) return Promise.reject(res)
            })

            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "warning"
                });
                console.log("保存日程失败", reason);
            })

            .then(() => this.posting = false)
		},

        // 发送请求保存日程
        saveSchedule (data) {
            if (data) {
                this.posting = true;
                return this.$axios({
                    url: "api/schedule/saveSchedule",
                    method: "post",
                    data,
                })

            } else {
                return Promise.reject("没有数据供保存")
            }
        },

        // 将新日程推入日期
        pushPlanToDate (plan, date) {
            if (!plan) return Promise.reject("没有传入日程，无需推入指定日期")
            if (!date) return Promise.reject("没有目标日期，不知推入哪一天")
            // 获取目标数据，如果没有，就设置一个新的数组
            let nArray = this.tableData[date];
            if (!(nArray instanceof Array)) {
                nArray = [];
                this.$set(this.tableData, date, nArray);
            }
            nArray.push(plan); // 推入新数组
        },

		// 关闭对话框
		handleDialogClose() {
			this.PB = null
		},

        // 点击日程项
        handlePlanClick(cell, index) {
            this.detailItem = this.tableData[cell.day][index];
        },

        // 点击详情对话框的关闭图标
        handleDetailDialogClose () {
            this.detailItem = null;
        },

        // 点击详情对话框里面的 删除日程 按钮
        handleDetailButton() {
            this.$confirm("要删除这个日程吗")

            .then(() => {
                this.posting = true;

                return this.$axios({
                    url: "api/schedule/deleteSchedule",
                    method: "post",
                    data: { id : this.detailItem.id }
                })
            })

            .then(res => {
                const success = 2000 == res.code;

                this.$message({
                    message: success ? "删除成功" : (res.msg || "删除失败"),
                    type: success ? "success" : "warning"
                })

                if (success) {
                    const key = this.detailItem.startTime.slice(0,10);
                    const arr = this.tableData[key];
                    const index = arr.indexOf(this.detailItem);
                    arr.splice(index, 1);
                }
                this.detailItem = null;
                this.posting = false;
            })

            .catch(reason => {
                console.warn(reason);
                this.loading = false;
            })

        },

        // 拖拽开始事件：记录拖拽的元素
        dragstart(cell, index){
            this.dragFromKey = cell.day;
            this.dragFromIndex = index;
        },

        // 拖拽进入事件：修改容器的样式，让其看起来像是激活的状态
        dragenter(e){
            e.target.classList.add("active");
        },

        // 拖拽离开事件：复位容器的样式
        dragleave(e){
            e.target.classList.remove("active");
        },

        // 拖拽经过事件：需要阻止默认行为，不然不会触发drop方法
        dragover(e){
            e.preventDefault();
        },

        // 拖拽放入事件：更新数据状态，并同步至后端
        drop(e, cell){

            const newDate = cell.day;                 // 目标日期 yyyy-mm-dd
            const fromDate = this.dragFromKey;        // 来源日期 yyyy-mm-dd
            const oArray = this.tableData[fromDate];  // 原日期的数据数组
            const fromIndex = this.dragFromIndex;     // 原日期数组中 index
            const item = oArray[fromIndex];           // 被拖拽的数据

            /**
             * 重置拖拽状态为空, 并复位容器样式，dragenter 和 dragleave
             * 因为添加了 self 修饰符，所以不用查找祖先元素;
             */
            e.target.closest(".calendar-cell")?.classList.remove("active");
            this.dragFromKey = "";
            this.dragFromIndex = "";
            if (newDate === fromDate) return;

            /**
             * 如果新日期和原日期不一样，保存日期为新日期的日程，成功后更新视图
             */
            const newData = {...item, startTime: newDate + item.startTime.slice(10)}
            this.saveSchedule(newData)
            .catch(() => this.saveSchedule(newData)) // 保存失败时重新提交一次

            .then(res => {
                if (res.code != 2000) return Promise.reject(res);
                // 获取目标数据，如果没有，就设置一个新的数组
                // let nArray = this.tableData[newDate];
                // if (!(nArray instanceof Array)) {
                //     nArray = [];
                //     this.$set(this.tableData, newDate, nArray);
                // }
                // nArray.push(newData);        // 新数组移入
                oArray.splice(fromIndex, 1)           // 原数组移除
                this.pushPlanToDate(newData, newDate) // 新数组移入
                this.posting = false
            })

            .catch(reason => {
                console.warn(reason);
                this.$message.warning(reason?.msg || "变更失败，请稍后再试")

            })
        },

    },

	created () {
		this.auth.save = this.$hasAuthFor("api/schedule/saveSchedule");
		this.auth.delete = this.$hasAuthFor("api/schedule/deleteSchedule");
	},

    mounted () {
        // console.log("日历组件", this.$refs.calendar)
    }

}