<!-- 物流信息 -->
<template>
    <el-dialog class="express-list"
        :visible="viewIsReady"
        @close="handleClose"
        title="物流信息"
        width="860px">

        <el-table :data="tableData">
            <el-table-column label="单号" prop="express_no" class-name="express_no"></el-table-column>
            <el-table-column label="物流公司" prop="express_com"></el-table-column>
            <el-table-column label="商品名称" prop="goodsname"></el-table-column>
            <el-table-column label="物流信息" min-width="300px">
                <template slot-scope="scope">
                    <div class="process-item"
                        v-for="(item, j) in scope.row.expressData"
                        :key="j + item.ftime"
                    >
                        {{item.ftime}} {{item.context}}
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <div slot="footer">
            <el-button @click="handleClose">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ExpressList',
    props: [ "item" ],

    data () {
        return {
            viewIsReady: false,
            tableData: []
        }
    },

    computed: {
        replaceItem () {
            return this.item || {}
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.viewIsReady = true;
                this.fetchData();
            }
        }
    },

    methods: {
        async fetchData () {
            const loading = this.$loading({
                target: ".express-list .el-dialog"
            });

            try {
                const res = await this.$axios({
                    method: "post",
                    url   : "out/queryOrderExpress",
                    data  : {
                        orderNo: this.item.order_no
                    }
                });

                if (res.code !== 2000 && res.code !== 1003) throw res;

                this.tableData = res.data.expressList;

            } catch (error) {
                console.warn("获取订单物流信息失败", error);
                this.$message({
                    message: error.msg || "获取订单物流信息失败",
                    type: "warning"
                });
            }

            loading?.close();
        },

        resetData () {
            this.tableData = [];
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>

.process-item {
    position: relative;
    padding-left: 16px;
    line-height: 16px;
    font-size: 12px;

    &:nth-child(n+2) {
        margin-top: 8px;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 4px;
        height: 4px;
        border: 2px solid #CD944D;
        border-radius: 50%;
    }
}
</style>