// 系统设置

export default {
    path: "/system",
    name: "System",
    component: () =>
        import(
            /* webpackChunkName: "system" */
            "../views/system/system.vue"
        ),
    title: "系统设置",
    icon: "system",

    children: [
        {
            path: "basic",
            name: "SystemBasic",
            component: () =>
                import(
                    /* webpackChunkName: "system" */
                    "../views/system/systemBasic/systemBasic.vue"
                ),
            title: "基础设置",
        },
        {
            path: "associate",
            name: "ExternalAssociate",
            component: () =>
                import(
                    /* webpackChunkName: "system" */
                    "../views/system/externalAssociate/externalAssociate.vue"
                ),
            title: "与其他系统配置",
        },
        {
            path: "menu",
            name: "SystemMenu",
            component: () =>
                import(
                    /* webpackChunkName: "system" */
                    "../views/system/systemMenu/systemMenu.vue"
                ),
            title: "菜单管理",
        },
        

        {
            path: "roles",
            name: "SystemRoles",
            component: () =>
                import(
                    /* webpackChunkName: "system" */
                    "../views/system/systemRoles/systemRoles.vue"
                ),
            title: "角色管理",
        },
        {
            path: "roles/add",
            name: "SystemRoleAdd",
            component: () =>
                import(
                    /* webpackChunkName: "system" */
                    "../views/system/systemRoles/systemRoleEdit/systemRoleEdit.vue"
                ),
            title: "新增角色账号",
            awayMenu: true // 不出现在导航菜单上
        },
        {
            path: "roles/edit/:id",
            name: "SystemRoleEdit",
            component: () =>
                import(
                    /* webpackChunkName: "system" */
                    "../views/system/systemRoles/systemRoleEdit/systemRoleEdit.vue"
                ),
            title: "编辑角色账号",
            awayMenu: true // 不出现在导航菜单上
        },



        {
            path: "whiteList",
            name: "WhiteList",
            component: () =>
                import(
                    /* webpackChunkName: "system" */
                    "../views/system/whiteList/whiteList.vue"
                ),
            title: "白名单",
            descreption: "管理白名单",
        },
        {
            path: "logs",
            name: "SystemLogs",
            component: () =>
                import(
                    /* webpackChunkName: "system" */
                    "../views/system/systemLogs/systemLogs.vue"
                ),
            title: "操作日志列表",
            descreption: "查看操作日志",
        },
        {
            path: "messageLogs",
            name: "MessageLogs",
            component: () =>
                import(
                    /* webpackChunkName: "system" */
                    "../views/system/messageLogs/messageLogs.vue"
                ),
            title: "短信登录日志",
            descreption: "查看短信登录日志",
        },
    ],
};